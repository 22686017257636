import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AcceptRejectConfirmationModalComponent } from 'src/app/admin/company/accept-reject-confirmation-modal/accept-reject-confirmation-modal.component';
import { StorageKeys } from 'src/app/share/enums/storage.enum';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { environment } from '../../../../environments/environment';
import { APPTYPES } from 'src/app/share/constants/common-constants';
import { CommonService } from 'src/app/share/services/common/common.service';
import { DocumentValutService } from 'src/app/share/document-valut/document-valut.service';
import { ViewDocumentComponent } from 'src/app/document-valut/view-document/view-document.component';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})

export class UserDetailsComponent implements OnInit {
  @Input() companyId        : any;
  @Input() parentData       : any;
  @Input() permissionData   : any; 
  @Output() closePanel      : EventEmitter<void> = new EventEmitter<void>();
  @Output() editUser        : EventEmitter<any> = new EventEmitter<any>();
  @Output() userDeleted     : EventEmitter<any> = new EventEmitter<any>();
  @Output() disableMfa      : EventEmitter<any> = new EventEmitter<any>();
  userId                    : string;
  selfDetails               : any;
  isAdmin                   : boolean = false;
  isEsignApplicable         : boolean = true;
  isBlockChainApplicable    : boolean = true;
  isMFA                     : boolean = false;
  envType = environment?.envType || 'SDEX';
  appType = APPTYPES;
  isToaster                 : boolean = false;
  message                   :string


  constructor(
    private modal: NgbModal,
    private storageService: StorageService,
    private userSettingService:UserSettingService,
    public commonService         : CommonService,
    private documentService: DocumentValutService,

  ) { }

  onCloseIconClick() {
    this.closePanel.emit();
    document.body.classList.remove('panel-open');
  }

  ngOnInit(): void {
    if(this.parentData!== undefined){
    this.parentData = JSON.parse(JSON.stringify(this.parentData));
    }
    this.selfDetails = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    if (this.selfDetails) {
      this.userId = this.selfDetails._id
    }
    if (this.selfDetails?.enrollmentType === "Admin") {
      this.isAdmin = true;
    }
    this.getBlockChainKey();
  }

  deleteUser(id: string) {
    let dialogRef = this.modal.open(AcceptRejectConfirmationModalComponent, {
      backdrop: 'static',
      size: 'md',
      keyboard: false,
      centered: true,
    });
    dialogRef.componentInstance.userId = this.parentData?._id;
    dialogRef.componentInstance.buttonLabel = this.parentData?.status == 'Active' ? 'Lock' : 'Unlock';
    dialogRef.componentInstance.message = `Are you sure you want to ${this.parentData?.status == 'Active' ? 'lock' : 'unlock'} the user '${this.parentData.name ? this.parentData.name : ""}' ?`;
    dialogRef.result.then(
      (result) => {
        if (result?.status) {
          const action = this.parentData?.status === 'Active' ? 'locked' : 'unlocked';
          let message = `User '${this.parentData.name}' has been ${action} successfully!`;
          this.isToaster = true;
          this.closePanel.emit();
          this.userDeleted.emit({isToaster:true,message:message});
          document.body.classList.remove('panel-open');

        }

      },
      (reason) => {

      }
    );
  }

  
  closeToaster(){
    this.isToaster =false;
  }
  handleEditUser() {
    this.editUser.emit(this.parentData);
  }

  getBlockChainKey() {
    this.userSettingService.getblockchainkey(this.parentData?._id)?.subscribe(response => {
      this.parentData.isKeyGenerated = response.data.keyGenerated;
    });
  }

  disabledMFA() {
    this.disableMfa.emit();
  }

  closeMFAPanel(){
    this.isMFA = false;
  }
  onChange(e: any) {
    console.log(e);
  }

  getFile(id: string) {
    this.documentService.getFileById(id).subscribe((res: any) => {
      this.viewFile(res?.data);
    })
  }

  viewFile(file: any) {
    this.parentData.downloadLoader = true;
    this.documentService.exportEsignDocument({ _id: file._id, download: true }, true)?.subscribe({
      next: (response: any) => {
        file.isViewLoader = false;
        let viewDetails = { file: file, data: response };
        let files = new Blob([viewDetails?.data?.body], {
          type: viewDetails?.file?.mimeType
        });
        let pdfSrc = URL.createObjectURL(files);
        const modelRef = this.modal.open(ViewDocumentComponent, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg',
        });
        this.parentData.downloadLoader = false;
        if (modelRef?.componentInstance) {
          modelRef.componentInstance.viewDetails = viewDetails;
          modelRef.componentInstance.pdfSrc = pdfSrc;
          modelRef.componentInstance.isSearch = true;
          modelRef.componentInstance.viewEsign = true;
          modelRef.result.finally(() => {
            this.parentData.downloadLoader = false;
          });
        }
      },
      error: (err: any) => {
        this.parentData.downloadLoader = false;
      }
    })
  }

}
