<div class="offCanvas__container">
  <form [formGroup]="companySettingsFormGroup" (ngSubmit)="saveProfile()">
    <div class="offCanvas__contentWrapper">
      <div class="offCanvas__headerWrapper">
        <div class="offCanvas__header">
          <div class="offCanvas__title">{{isLRAddress ? 'Update Address' : 'Update Organisation Details'}}</div>
          <img (click)="onCloseIconClick()" class="offCanvas__crossIcon"
            src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon" />
        </div>
      </div>
      <div class="offcanvas__content">
        <div class="companyNameDiv" *ngIf="!isLRAddress">
          <app-input-filed label="Company Short Name" [value]="companyName.value" [control]="companyName"
            placeholder="Company Short Name"></app-input-filed>
        </div>
        <div>
          <div *ngIf="!isLRAddress" class="organisationalDetails__imageContainer">
            <div class="card ">
              <div class="card-header">Logo <small>(min 200 * 70 px)</small></div>
              <div class="profile-container card-body">
                <div class="profile-image" (click)="file.click()">
                  <ng-container *ngIf="orgDetails?.logoDetails?.value">
                    <img src="{{ orgDetails?.logoDetails?.value}}" alt="Image" />
                    <span><fa-icon [icon]="faCamera"></fa-icon></span>
                  </ng-container>
                  <ng-container *ngIf="!orgDetails?.logoDetails?.value">
                    <fa-icon [icon]="userCircleIcon" style="color: gray; font-size: 100px">
                    </fa-icon>
                    <span><fa-icon [icon]="faCamera"></fa-icon></span>
                  </ng-container>
                </div>
                <div class="profile-change">
                  <input style="display: none" #file type="file" accept="image/*,jpg,png" (change)="uploadImage('logo',$event)" />
                </div>
              </div>
            </div>
            <!-- fevicon -->
            <div class="card">
              <div class="card-header">Favicon <small>(min 40 * 40 px) </small> </div>
              <div class="profile-container card-body">
                <div class="profile-image" (click)="fileFavicon.click()">
                  <ng-container *ngIf="orgDetails?.favIconDetails?.value">
                    <img src="{{ orgDetails?.favIconDetails?.value}}" alt="Image" />
                    <span><fa-icon [icon]="faCamera"></fa-icon></span>
                  </ng-container>
                  <ng-container *ngIf="!orgDetails?.favIconDetails?.value">
                    <fa-icon [icon]="userCircleIcon" style="color: gray; font-size: 100px">
                    </fa-icon>
                    <span><fa-icon [icon]="faCamera"></fa-icon></span>
                  </ng-container>
                </div>
                <div class="profile-change">
                  <input style="display: none" #fileFavicon type="file" accept="image/*,jpg,png"
                    (change)="uploadImage('favIcon',$event)" />
                </div>
              </div>
            </div>
            <!-- fevicon -->
            <div class="card">
              <div class="card-header">Mini Logo <small>(min 40 * 40 px)</small></div>
              <div class="profile-container card-body">
                <div class="profile-image" (click)="miniLogo.click()">
                  <ng-container *ngIf="orgDetails?.miniLogoDetails?.value">
                    <img src="{{ orgDetails?.miniLogoDetails?.value}}" alt="Image" />
                  </ng-container>
                  <ng-container *ngIf="!orgDetails?.miniLogoDetails?.value">
                    <fa-icon [icon]="userCircleIcon" style="color: gray; font-size: 100px">
                    </fa-icon>
                  </ng-container>
                  <span><fa-icon [icon]="faCamera"></fa-icon></span>
                </div>
                <div class="profile-change">
                  <input style="display: none" #miniLogo type="file" accept="image/*,jpg,png"
                    (change)="uploadImage('miniLogo',$event)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <small class="text-danger">{{fileErrorMessage}}</small>
        </div>
        <div class="addressDiv">
          <h3>Address</h3>
          <div class="row">
            <div class="col-md-8">
              <app-input-filed label="Address Line 1" [control]="$any(companySettingsFormGroup).controls['line1']"
                placeholder="Address Line 1" [value]="address?.line1" [starIcon]="'*'"></app-input-filed>
              <small class="text-danger"
                *ngIf="companySettingsFormGroup.get('line1')?.invalid && companySettingsFormGroup.get('line1')?.touched">
                Address Line 1 is required.
              </small>
            </div>
            <div class="col-md-4">
              <app-input-filed label="Locality" [control]="$any(companySettingsFormGroup).controls['locality']"
                placeholder="Locality" [value]="address?.locality"></app-input-filed>
            </div>
            <div class="col-md-8 mt-2">
              <app-input-filed label="Address Line 2" [control]="$any(companySettingsFormGroup).controls['line2']"
                placeholder="Address Line 2" [value]="address?.line2"></app-input-filed>
            </div>
            <div class="col-md-4 mt-2">
              <app-input-filed label="Landmark" [control]="$any(companySettingsFormGroup).controls['landmark']"
                placeholder="Landmark" [value]="address?.landmark"></app-input-filed>
            </div>
            <div class="col-md-4 mt-2">
              <app-input-filed label="City" [control]="$any(companySettingsFormGroup).controls['city']"
                placeholder="City" [value]="address?.city" [starIcon]="'*'"></app-input-filed>
              <small class="text-danger"
                *ngIf="companySettingsFormGroup.get('city')?.invalid && companySettingsFormGroup.get('city')?.touched">
                City is required.
              </small>
            </div>
            <div class="col-md-4 mt-2"> <app-input-filed label="State"
                [control]="$any(companySettingsFormGroup).controls['state']" placeholder="State"
                [value]="address?.state" [allowedType]="'chars'" [starIcon]="'*'"></app-input-filed>
              <small class="text-danger"
                *ngIf="companySettingsFormGroup.get('state')?.invalid && companySettingsFormGroup.get('state')?.touched">
                State is required.
              </small>
            </div>
            <div class="col-md-4 mt-2">
              <app-input-filed label="Pincode" [control]="$any(companySettingsFormGroup).controls['pincode']"
                placeholder="Pincode" [value]="address?.pincode" [maxDigits]="6" (input)="pincodeValidation()"
                type="number" [starIcon]="'*'"></app-input-filed>
              <small class="text-danger"
                *ngIf="companySettingsFormGroup.get('pincode')?.invalid && companySettingsFormGroup.get('pincode')?.touched">
                Pincode is required.
              </small>
            </div>
            <div class="col-md-4 mt-2">
              <app-input-filed *ngIf="isLRAddress" label="Country"
                [control]="$any(companySettingsFormGroup).controls['country']" placeholder="Country"
                [value]="address?.country"></app-input-filed>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="offCanvas__footer">
      <div class="text-danger">
        {{prefError}}
      </div>
      <app-common-button type="button" (click)="onCloseIconClick()" buttonClass="smallbutton"
        buttonLabel="Cancel"></app-common-button>
      <div>
        <app-common-button type="submit" buttonClass="smallFilledButton" [loadingIcon]="isLoading" buttonLabel="Save"
          [disabled]="companySettingsFormGroup.invalid"></app-common-button>
      </div>
    </div>
  </form>
</div>