<div class="modal-header">
    <h4 *ngIf="envType == appType.sdex" class="modal-title " id="modal-basic-title">{{ viewDetails?.file?.customFileName
        ? viewDetails?.file?.customFileName
        : viewDetails?.file?.name}}</h4>
    <h4 *ngIf="envType == appType.dams" class="modal-title " id="modal-basic-title">{{ viewDetails?.file?.documentNumber
        ? viewDetails?.file?.documentNumber
        : viewDetails?.file?.name}}</h4>
    <div class="d-flex justify-content-start gap-2">
        <ng-container *ngIf="isSearch && !viewEsign">
            <app-input-filed [autocomplete]="'off'" [control]="searchControl" [value]="searchControl.value" (inputEvent)="onSearch($event)"  placeholder="Search"></app-input-filed>
            <small *ngIf="searchControl.value" (click)="clearSearch()" class="close-icon btn-close"
                aria-label="close"></small>
        </ng-container>

        <!-- <div  class=" close cursor-pointer">
            <img src="../../../assets/Images/document-preview/Download.svg" alt="cross icon">
        </div> -->
        <app-common-icon [iconSrc]="'../../../assets/Images/document-preview/Download.svg'"
                           [toolTip]="'Download'" 
                           [className]="'document-info--options__Icons pointer-cursor'" [placement]="'left'"
                           (clickEvent)="download()"></app-common-icon>
        <div class=" close cursor-pointer">
            <img src="../../../assets/Images/document-preview/Vector 234250852.svg" alt="cross icon">
        </div>
       
        <div (click)="modal.dismiss( 'Cross click')" class=" close cursor-pointer">
            <img src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon">
        </div>
    </div>
</div>

<div class="modal-body" [ngClass]="{'body-div': isSearch === true}">
    <div #fileContainer>
        <div *ngIf="noPreview" class="no-preview-div">
            <span>Preview cannot be loaded. Please download to view.</span>
        </div>
    </div>

    <div class="pdf-page-load" *ngIf="pdfSrc">
        <pdf-viewer class="pdf-container" [src]="pdfSrc" [zoom]="0.90" [show-all]="true" [render-text]="true"
            [render-text-mode]="1" [original-size]="false" [fit-to-page]="false" [zoom-scale]="'page-width'"
            [stick-to-page]="false" [autoresize]="true" [show-borders]="false"
            style="display: block; height: 100vh;z-index: 9999;"
            (text-layer-rendered)="fileLoadCompleated($event)"></pdf-viewer>
    </div>
</div>

