import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faCamera, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { APPTYPES } from 'src/app/share/constants/common-constants';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-company-settings',
  templateUrl: './update-company-settings.component.html',
  styleUrls: ['./update-company-settings.component.scss']
})
export class UpdateCompanySettingsComponent implements OnInit, OnChanges {

  @Input() address: any;
  @Input() addressInfo: any;
  @Input() organisationDetailsData: any;
  @Input() companyLogo: any;
  @Input() favIconLogo: any;
  @Input() isLRAddress: boolean;
  @Output() closePanel: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveCompanyProfile: EventEmitter<any> = new EventEmitter<any>();
  companySettingsFormGroup: FormGroup;
  profileImage: any;
  favIcon: any;
  errorMessage: any;
  errorMessage1: any;
  @Input() verifyLoader: boolean = false;
  envType = environment?.envType || 'SDEX';
  appType = APPTYPES;
  companyName: FormControl = new FormControl('', [Validators.required]);
  orgDetails: any = {
    addressDetails : {},
    logoDetails : {},
    favIconDetails : {},
    companyNameDetails : {},
    miniLogoDetails : {},
  };
  fileErrorMessage : string = "";
  isLoading : boolean = false;
  prefError : string = '';

  constructor(
    private fb: FormBuilder,
    private userSettingService: UserSettingService) { }

  ngOnInit(): void {
    if (!this.companySettingsFormGroup) {
      this.createCompanySettingsForm();
    }
    if (this.isLRAddress) {
      this.companySettingsFormGroup.addControl('country', new FormControl(this.addressInfo?.country));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['organisationDetailsData']?.currentValue){
      let data = changes['organisationDetailsData']?.currentValue;
      this.setDetails(data);
    }
    if (!this.companySettingsFormGroup) {
      this.createCompanySettingsForm();
    }
    const previousThis = this;
    if (this.addressInfo) {
      setTimeout(function () {
        previousThis.setCompayAddress(previousThis.addressInfo);
      })
    }
  }

  setDetails(dataArray:any[]) {
    dataArray.forEach((item: any) => {
      this.orgDetails[`${item.key}Details`] = { 
        prefId : item?._id,
        value : item?.value || item?.address || '',
        key : item?.key
      };
    });
  }

  createCompanySettingsForm() {
    let address: any = this.orgDetails['addressDetails']?.value || {}
    this.companySettingsFormGroup = this.fb.group({
      line2: [address?.line2],
      line1: [address?.line1, [Validators.required]],
      locality: [address?.locality],
      landmark: [address?.landmark],
      city: [address?.city, [Validators.required]],
      state: [address?.state, [Validators.required]],
      pincode: [address?.pincode, [Validators.required]],
    })
    
    this.companyName.setValue(this.orgDetails['companyNameDetails']?.value)
  }

  pincodeValidation() {
    const pincode = this.companySettingsFormGroup.value.pincode;
    if (pincode.charAt(0) == '0') {
      this.companySettingsFormGroup.patchValue({ pincode: pincode.substring(1) })
    } else {
      this.companySettingsFormGroup.patchValue({ pincode: pincode })
    }
  }


  setCompayAddress(value: any) {
    this.companySettingsFormGroup.patchValue({
      line1: value?.line1,
      line2: value?.line2,
      locality: value?.locality,
      landmark: value?.landmark,
      city: value?.city,
      state: value?.state,
      pincode: value?.pincode,
    })
  }

  uploadImage(type: string, event: any) {
    this.fileErrorMessage = "";
    const file = event.target.files[0];
    if (file) {
      if (!this.checkFileType(file)) {
        this.fileErrorMessage = "Only jpeg, jpg and png file types are allowed"
        return;
      }
      if (file.size / 1024 > 5000) {
        this.fileErrorMessage = "Image size should be less then 5MB"
        return;
      }
      let widthAndHeight: any = this.setWidthAndHeight(type);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {
          if (img.width >= widthAndHeight.width && img.height >= widthAndHeight.height) {
            this.fileErrorMessage = "";
            this.getImageUrl(file, type);

          } else {
            this.fileErrorMessage = `Image should be min of width ${widthAndHeight.width}px and height ${widthAndHeight.height}px`;
          }
        };

        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    }
  }

  getImageUrl(file: any, type:string) {
    let formData = new FormData();
    formData.append('fileFavicon', file, file.name);
    this.userSettingService.uploadFile(formData).subscribe({
      next: (res: any) => {
        this.orgDetails[`${type}Details`].value = res.data[0]?.objectUrl
      },
      error: (error: any) => {
        this.errorMessage = error?.error?.error?.message || `Failed to upload favicon. Please try again`;
      }
    });
  }

  setWidthAndHeight(type: string) {
    if (type === 'logo') {
      return { width: 200, height: 70 }
    } else {
      return { width: 40, height: 40 }
    }
  }

  checkFileType(file: File): boolean {
    return file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png';
  }

  saveProfile() {
    if(this.isLRAddress){
      const payload = {
        address: this.companySettingsFormGroup.value,
        profileImage: this.profileImage,
        favIcon: this.favIcon,
        companyName: this.companyName?.value
      };
      document.body.classList.remove('panel-open');
      this.saveCompanyProfile.emit(payload);
    }else{
      this.savePref()
    }
   
  }

  savePref(){
    let payload: any = [this.orgDetails.miniLogoDetails,this.orgDetails.logoDetails,this.orgDetails.favIconDetails];
    this.orgDetails.companyNameDetails.value = this.companyName?.value;
    this.orgDetails.addressDetails.value = this.companySettingsFormGroup.value;
    payload.push(this.orgDetails.companyNameDetails);
    payload.push(this.orgDetails.addressDetails);
    this.isLoading = true;
    this.userSettingService.saveCompanyPreferences({prefereces : payload}).subscribe({
      next : (res: any)=>{
        this.isLoading = false;
        document.body.classList.remove('panel-open');
        this.saveCompanyProfile.emit(res.data);
      },
      error: (error: any)=>{
        this.isLoading = false;
        this.prefError = error?.error?.error?.message ? error?.error?.error?.message : 'Something went wrong! please try again';
        console.log(error);
      }
    })
  }


  onCloseIconClick() {
    this.closePanel.emit(false);
    this.organisationDetailsData.profileImage = this.companyLogo;
    this.organisationDetailsData.favIcon = this.favIconLogo;
    document.body.classList.remove('panel-open');
    this.errorMessage1 = "";
  }

  protected readonly faCamera = faCamera;
  protected readonly userCircleIcon = faUserCircle;

}
