import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFSource, PdfViewerComponent } from 'ng2-pdf-viewer';
import { APPTYPES } from 'src/app/share/constants/common-constants';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-view-document',
    templateUrl: './view-document.component.html',
    styleUrls: ['./view-document.component.scss']
})
export class ViewDocumentComponent implements AfterViewInit {
    @Input() viewDetails: any;
    @Input() pdfSrc: string | Uint8Array | PDFSource;
    @Input() viewEsign: boolean;
    @Input() searchOption: any;
    @ViewChild('fileContainer') fileContainer: ElementRef;
    @ViewChild(PdfViewerComponent, { static: false }) private pdfViewer: PdfViewerComponent;
    @Output() fileActionEvent: EventEmitter<any> = new EventEmitter<any>();

    envType = environment?.envType || 'SDEX';
    appType = APPTYPES;
    faDownload = faDownload;
    noPreview = false;
    isSearch: boolean = false;
    searchText: any = [];
    searchControl : FormControl;
    downloadLoader: boolean = false;

    constructor(public modal: NgbActiveModal) {
        this.searchControl = new FormControl('');
     }

    onSearch(event: any) {
        let searchText = event.target.value.trim();
        if (this.searchOption === 'exact') {
            this.searchText = searchText;
        } else {
            this.searchText = searchText?.split(' ');
        }
        this.pdfSearchTextHighlighted();
    }

    pdfSearchTextHighlighted() {
        if (this.viewDetails?.file?.mimeType?.indexOf('pdf') !== -1) {
            this.pdfViewer?.pdfFindController._eventBus.dispatch('find', {
                query: this.searchText, 
                type: 'again', 
                caseSensitive: false, 
                findPrevious: undefined, 
                highlightAll: true, 
                phraseSearch: true,
            });
        } else {
            this.noPreview = true;
        }
    }

    ngAfterViewInit() {
        let files = new Blob([this.viewDetails?.data?.body], {
            type: this.viewDetails?.file?.mimeType
        });
        let url = URL.createObjectURL(files);
        if (this.viewDetails?.file?.mimeType?.indexOf('image') !== -1) {
            let _image = document.createElement('img');
            _image.setAttribute('src', url);
            _image.setAttribute('width', '100%');
            _image.setAttribute('max-height', '800');
            this.fileContainer.nativeElement.append(_image);
        } else if (this.viewDetails?.file?.mimeType?.indexOf('pdf') !== -1 && !this.isSearch) {
            let _iFrame = document.createElement('iframe');
            _iFrame.setAttribute('src', url);
            _iFrame.setAttribute('scrolling', 'auto');
            _iFrame.setAttribute('height', '800');
            _iFrame.setAttribute('width', '100%');
            this.fileContainer.nativeElement.append(_iFrame);
        } else if(!this.isSearch) {
            this.noPreview = true;
        }
    }

    download() {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(this.viewDetails?.data?.body);
        link.download = this.viewDetails?.file?.name;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
    }

    fileLoadCompleated(event: any){
        this.pdfSearchTextHighlighted();
    }

    clearSearch() {
        this.searchControl.reset();
        this.searchText = '';
        this.pdfSearchTextHighlighted();
    }

    downloadFile() {
        this.downloadLoader = true;
        this.fileActionEvent.next({ actionType: 'download' })
        setTimeout(() => {
            this.downloadLoader = false;
        }, 3000)
    }
}
