<div class="userDetails__body">
  <div class="userDetails__headerWrapper">
    <div class="userDetails__header">
      <div class="userDetails__headerText">User details </div>
    
      <div class="userDetails__headerImages">
        <ng-container *ngIf="commonService.orgLock()">
          <ng-container *ngIf="userId !== parentData?._id">
            <img data-bs-toggle="tooltip" ngbTooltip="Edit" placement="left" appAccessControl allowedRoles="user.edit"
              class="userDetails__crossIcon" (click)="handleEditUser()"
              src="../../../../assets/Images/user-management/edit.svg" alt="edit" />
          </ng-container>
          <ng-container *ngIf="
                          userId !== parentData?._id &&
                          ((parentData?.rootCompany || parentData?.company) !== parentData?.company?._id) &&
                          !parentData.primary && parentData.status != 'New'
                        ">
            <div class="userDetails__border" appAccessControl allowedRoles="user.edit"></div>
            <img data-bs-toggle="tooltip" [ngbTooltip]="parentData?.status=='Active'?'Lock':'Unlock'" placement="left"
              appAccessControl allowedRoles="user.disable,user.delete" (click)="deleteUser(parentData?._id)"
              class="userDetails__crossIcon lock-img" src="../../../../assets/Images/user-management/user-lock.svg"
              alt="delete" />
          </ng-container>
        </ng-container>
        <div class="userDetails__border" appAccessControl allowedRoles="user.disable"></div>
        <img (click)="onCloseIconClick()" class="userDetails__crossIcon"
          src="../../../../assets/Images/user-management/Cross.svg" alt="close" />
      </div>
    </div>
  </div>
  <div class="userDetails__basicdetailsWrapper">
    <div class="userDetails__basicdetailsSection">
      <div class="mfa-disable">
        <div class="userDetails__subText">Basic details:
        </div>
        <div *ngIf="selfDetails?.primary && commonService.orgLock()" class="btn-mfa">
          <app-common-button (clickEvent)="disabledMFA()" buttonClass="smallbutton" buttonLabel="MFA"></app-common-button>
        </div>
      </div>
      <div>
        <div class="userDetails__nameheader">Name</div>
        <div class="userDetails__name">{{ parentData?.name }}</div>
      </div>
      <div>
        <div class="userDetails__nameheader">Email</div>
        <div class="userDetails__name">{{ parentData?.emailAddress }}</div>
      </div>
      <div class="userDetails__mobileDetails">
        <div class="userDetails__nameheader">Mobile Number</div>
        <div class="userDetails__name">{{ parentData?.mobileNumber }}</div>
      </div>
    </div>
  </div>
  <div class="userDetails__basicdetailsWrapper">
    <div class="userDetails__basicdetailsSection">
      <div class="userDetails__subText">{{!isAdmin?'Branches assigned:':'Roles assigned:'}}</div>
      <ng-container *ngFor="let branch of parentData?.assignedTo">
        <div class="assigned_block">
          <div class="d-flex trow">
            <div class="roles_container" *ngIf="!isAdmin">
              <p class="userDetails__nameheader">Branch</p>
            </div>
            <div class="roles_container">
              <p class="userDetails__nameheader">Roles</p>
            </div>
          </div>
          <div>
            <div class="d-flex">
              <div [class]="!isAdmin? 'roles_container':'hideBranch'" >
                <p class="userDetails__name">{{ branch?.company?.name | uppercase }}</p>
              </div>
              <div>
                <p *ngIf="branch.isAdmin" class="userDetails__name">Admin{{ " " }}</p>
                <ng-container *ngFor="let role of branch.roles">
                  <p class="userDetails__name">{{ role?.name }} {{ " " }}</p>
              </ng-container>
                <ng-container *ngIf="branch.roles.length == 0 && !branch.isAdmin">
                  <p class="userDetails__name">NA</p>
              </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="parentData?.assignedTo?.length == 0">
        <div class="assigned_block">
          <div class="d-flex trow">
            <div class="roles_container">
              <p class="userDetails__nameheader">Branch</p>
            </div>
            <div class="roles_container">
              <p class="userDetails__nameheader">Roles</p>
            </div>
          </div>
          <div>
            <div class="d-flex">
              <div [class]="!isAdmin? 'roles_container':'hideBranch'" >
                <p class="userDetails__name">NA</p>
              </div>
              <div>
                <ng-container>
                  <p class="userDetails__name">NA</p>
              </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="userDetails__Section">
    <div class="userDetails__verification" *ngIf="!isAdmin">
      <div class="userDetails__subText">Verification status</div>
      <div class="userDetails__verificationDetails">
        <div class="row">
          <div class="col-lg-6">
            <div class="d-flex justify-content-between block_width">
              <div class="userDetails__nameheader">E-Sign</div>
              <div *ngIf="parentData?.eSignedAt">
                <app-common-icon [iconSrc]="'../../../../assets/Images/login/eye.svg'" class="" [toolTip]="!parentData?.downloadLoader ? 'E-Sign Preview' : ''"
                [loadingIcon]='parentData?.downloadLoader'
                (clickEvent)="getFile(parentData?.eSignSelfLink)" [className]="'document-info--options__Icons pointer-cursor'" [placement]="'top'"
                  ></app-common-icon>
              </div>
            </div>
            <div class="userDetails__verificationstatus">
              <div class="userDetails__verificationFieldStatus" *ngIf="parentData?.eSignedAt">
                <img src="../../../../assets/Images/user-management/Verified.svg" alt="verified" />
                <div class="userDetails__verificationFieldText">Verified</div>
              </div>
              <div class="userDetails__verificationFieldStatus" *ngIf="!parentData?.eSignedAt && permissionData?.isEsignApplicable">
                <img src="../../../../assets/Images/user-management/Unverified.svg" alt="unverified" />
                <div class="userDetails__verificationUnverified">Unverified</div>
              </div>
              <div class="userDetails__verificationFieldStatus" *ngIf="!permissionData?.isEsignApplicable && !parentData?.eSignedAt">
                <img src="../../../../assets/Images/user-management/Unverified.svg" alt="unverified" />
                <div class="userDetails__verificationUnverified">NA</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6" *ngIf="envType === appType.sdex">
            <div class="userDetails__nameheader">BlockchainKey</div>
            <div class="userDetails__verificationstatus">
              <div class="userDetails__verificationFieldStatus" *ngIf="parentData?.isKeyGenerated">
                <img src="../../../../assets/Images/user-management/Verified.svg" alt="verified" />
                <div class="userDetails__verificationFieldText">Verified</div>
              </div>
              <div class="userDetails__verificationFieldStatus" *ngIf="!parentData?.isKeyGenerated && permissionData?.isBlockChainApplicable">
                <img src="../../../../assets/Images/user-management/Unverified.svg" alt="unverified" />
                <div class="userDetails__verificationUnverified">Unverified</div>
              </div>
              <div class="userDetails__verificationFieldStatus" *ngIf="!permissionData?.isBlockChainApplicable && !parentData?.isKeyGenerated">
                <img src="../../../../assets/Images/user-management/Unverified.svg" alt="unverified" />
                <div class="userDetails__verificationUnverified">NA</div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</div>
