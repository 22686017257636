<div class="company-head">
  <div class="lookupDataList __section">
    <div class="lookupDatalist__management listPage__Description" *ngIf="envType !== appType.clms">
      <app-list-page-header
        headerTitle="{{companyDoc?._id ? 'Metadata Fields: '+companyDoc.name : envType === appType.dams? 'Custom Document Category':envType === appType.clms ?'Master Data':''}}"
        headerDescription="{{companyDoc?._id ? 'Define Metadata Fileds for Category' : 'Manage all your Master data'}}"></app-list-page-header>
    </div>
  </div>
  <div *ngIf="tabvalue == 'documentmetadata'" style="display: flex;" >
    <app-common-button buttonClass="regularButton" buttonLabel="Back" (clickEvent)="gotoBack()"
      style="margin-right: 10px;"></app-common-button>
    <app-common-button appAccessControl restrictedModules="documentVault.ocrCategory" *ngIf="commonService.orgLock() && hasEditAccess" buttonClass="regularButton" buttonLabel="Add New" (clickEvent)="addLookup()"></app-common-button>
  </div>
  <ng-container *ngIf="commonService.orgLock() && commonService.hasAllowedAction()">
    <div *ngIf="!isEnvTypeClms && (tabvalue == 'documenttype'||tabvalue=='contracttype'||tabvalue=='recordtype')">
      <app-common-button buttonClass="regularButton" buttonLabel="Add New"
        (clickEvent)="AddDocumentType()"></app-common-button>
    </div>
  </ng-container>
  <div *ngIf="tabvalue == 'module'">
    <app-common-button buttonClass="regularButton" buttonLabel="Add New" (clickEvent)="addModule()"></app-common-button>
  </div>
</div>

<div *ngIf="isEnvTypeClms">
  <app-common-button buttonClass="regularButton" buttonLabel="Add New"
    (clickEvent)="openAddClmsMasterDataType()"></app-common-button>
</div>

<ul class="nav nav-tabs" id="myTab" role="tablist" *ngIf="tabvalue != 'documentmetadata'">
  <li *ngIf="envType === appType.dams" class="nav-item " [ngClass]="{ active: tabvalue === 'documenttype'}">
    <a data-toggle="tab" (click)="onTabselect('documenttype')">Document Category</a>
  </li>

  <li *ngIf="envType === appType.clms" class="nav-item" [ngClass]="{ active: tabvalue === 'recordtype'}">
    <a data-toggle="tab" (click)="onTabselect('recordtype')">Record Type</a>
  </li>

  <li *ngIf="envType === appType.clms" class="nav-item" [ngClass]="{ active: tabvalue === 'contracttype'}">
    <a data-toggle="tab" (click)="onTabselect('contracttype')">Contract Type</a>
  </li>
</ul>


<div *ngIf="tabvalue == 'documentmetadata'">
  <table class="custom-table-list" aria-label="cred_table">
    <thead>
      <tr class="custom-table-list-headings">
        <th class="custom-col-width-30">Label</th>
        <th class="custom-col-width-20">Required</th>
        <th class="custom-col-width-20">Field Type</th>
        <th class="custom-col-width-20">Status</th>
        <th class="custom-col-width-20">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr class="custom-table-list-row-main" *ngFor="let value of metadataList">
        <td class="custom-col-width-30">{{value?.label}}</td>
        <td class="custom-col-width-20">{{value?.validateRequire?'Yes':'No'}}</td>
        <td class="custom-col-width-20">{{value?.fieldType}}</td>
        <td class="custom-col-width-20">{{value?.status}}</td>
        <td class="custom-col-width-20">
          <span class="d-flex justify-content-start">
            <span data-bs-toggle="tooltip" ngbTooltip="Edit" [placement]="'top'" *ngIf="hasEditAccess">
              <div tooltipClass="custom-tooltip" (click)="editLookup(value)" class="btn btn-primary btn-sm me-2"
                style="background-color: #de7141; border-color: #de7141;">
                <fa-icon [icon]="faEdit"></fa-icon>
              </div>
            </span>
            <ng-container *ngIf="commonService.orgLock()">
              <app-common-icon *ngIf="value.status ==='Enabled' && hasEditAccess" [faIcon]="faBan"
                ngbTooltip="Disable" [placement]="'top'" class="btn btn-primary btn-sm me-2 custom-disable"
                (click)="disableMetadata(value,'Enabled')" [loadingIcon]="value.enabledLoader"></app-common-icon>
              <app-common-icon *ngIf="value.status ==='Disabled' && hasEditAccess" [faIcon]="faCircleCheck"
                ngbTooltip="Enable" [placement]="'top'" class="btn btn-primary btn-sm me-2 custom-disable"
                (click)="disableMetadata(value,'Disabled')" [loadingIcon]="value.disableLoader"></app-common-icon>
            </ng-container>
              <app-common-icon [faIcon]="faHistory" ngbTooltip="History" [placement]="'top'"
              class="btn btn-primary btn-sm me-2 custom-disable" (click)="getFieldHistory(value, fieldHistory)"
              [loadingIcon]="value.verifyLoader"></app-common-icon>
          </span>
        </td>
      </tr>
      <tr class="custom-table-list-row-main" *ngIf="isLoadRecords">
        <td class="custom-col-width-100 text-danger d-flex justify-content-center">
          <app-common-icon [loadingIcon]="isLoadRecords"></app-common-icon>
        </td>
      </tr>
      <tr class="custom-table-list-row-main" *ngIf="!isLoadRecords && metadataList?.length === 0">
        <td class="custom-col-width-100 text-danger d-flex justify-content-center">
          No Metadata Found!
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="envType === appType.dams && tabvalue == 'documenttype'">
  <app-document-type-list [tabvalue]="tabvalue" [isDocumentType]="isdocumenttype"
    (viewDoc)="viewDocmentType($event)"></app-document-type-list>
</div>
<div *ngIf="isEnvTypeClms">
  <div *ngIf="isLoadRecords">
        <div class="custom-col-width-100 text-danger d-flex justify-content-center">
          <app-common-icon [loadingIcon]="isLoadRecords"></app-common-icon>
        </div>
  </div>
  <div *ngIf="!isLoadRecords && masterData?.length === 0">
        <div class="pt-3 custom-col-width-100 text-danger d-flex justify-content-center">
          No {{tabvalue === 'contracttype' ? 'Contract Type' : 'Record Type'}} Found!
        </div>
  </div>
  <app-clms-contract-types [masterData]="masterData" (updateData)="updateContactTypesData($event)"
    [selectedType]="tabvalue"></app-clms-contract-types>
</div>


<div class="overlay" *ngIf="isLookup" [class.show]="isLookup"></div>
<div class="off-canvas width-450" *ngIf="isLookup" [class.show]="isLookup">
  <div class="off-canvas-content">
    <app-add-lookup [editItem]="editItem" [companyDoc]="companyDoc"
      (closePanel)="onCloseIconClick($event)" (submitForm)="onSubmit($event)"></app-add-lookup>
  </div>
</div>

<div class="toaster_message" *ngIf="isSuccessToster">
  <app-error-toaster [title]="uploadErrorMessage"></app-error-toaster>
</div>

<div class="toaster_message" *ngIf="isToasterMessage">
  <app-toast [title]="uploadErrorMessage" ></app-toast>
</div>

<div class="overlay" *ngIf="isEnvTypeClms" [class.show]="isOpenAddMasterDataPanel"></div>
<div class="off-canvas width-450" *ngIf="isOpenAddMasterDataPanel" [class.show]="isOpenAddMasterDataPanel">
  <div class="off-canvas-content">
    <app-clms-add-contract-type [selectedType]="tabvalue"
      (closePanel)="closeAddClmsMasterDataType($event)"></app-clms-add-contract-type>
  </div>
</div>



<ng-template tabindex="-1" #fieldHistory role="dialog" aria-labelledby="fieldHistory" aria-hidden="true"
  let-fieldHistory>
  <div class="modal-header">
    <h5 class="modal-title">{{label}}</h5>
    <div class="closeIcon" (click)="fieldHistory.dismiss(false)"><img
        src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon"></div>
  </div>
  <div class="modal-body">
    <table class="table" aria-label="metadataHistory">
      <thead>
        <tr>
          <th class="fieldHistory__Name">Created On</th>
          <th class="fieldHistory__Name">Messages</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of fieldHistoryList">
          <td class="fieldHistory__Name">{{item?.created | date:"dd MMM yy, hh:mm a"}}</td>
          <td class="fieldHistory__Name">
        <tr *ngFor="let msg of item.messages">
        <ul class="history-b">
          <li>{{msg}}</li>
        </ul>
        </tr>
        </td>
        </tr>
        <tr style="text-align: center;" *ngIf="fieldHistoryList.length === 0">
          <div class="text-danger"><small>No history found</small></div>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>