<div class="side-nav-bar__body">
  <div class="side-nav-bar__bodySection">
    <div class="side-nav-bar__logoWrapper" [ngClass]="{ 'side-nav-bar__logoBody': hideText }">
      <div class="side-nav-bar__logo">
        <div class="side-nav-bar__logoSection">
          <img src="../../../assets/Images/side-nav-bar/sdx__logo.svg" alt="sdex" />
          <img [hidden]="hideText" class="side-nav-bar__icon" src="../../../assets/Images/side-nav-bar/sdex__logo.svg"
            alt="sdex" />
        </div>
      </div>
    </div>
    <div class="side-nav-bar__content">
      <div class="side-nav-bar__contents {{user?.assignedTo?.length ? 'pb-3' : ''}}">
        <div [hidden]="hideText" *ngIf="user?.assignedTo?.length > 1">
          <select class="select org-select-dropdown" [(ngModel)]="selectedCompany" (change)="onCompanyChange($event)">
            <option *ngFor="let company of user.assignedTo" [value]="company.company._id">
              {{ company.company.name }}
            </option>
          </select>
        </div>
        <div *ngFor="let sideList of sideBarList" appAccessControl allowedRoles="{{sideList.roles}}" allowedModules="{{sideList.modules|| ''}}" skipAccess="{{!!sideList?.skipAccess}}">
          <div class="side-nav-bar__dashboard {{sideList.addBorder ? (hideText ? 'border-line-collapse' : 'border-line') : ''}}"
            [routerLink]="sideList.routerLink">
            <div routerLinkActive="side-nav-bar__active" routerLink="{{sideList.routerLink}}"></div>
            <img (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false" data-bs-toggle="tooltip"
              [ngbTooltip]="showTooltip ? sideList.label:''" [placement]="'right'" container="body" triggers="mouseenter"
              class="side-nav-bar__images" src="{{sideList.imgSrc}}" alt="{{sideList.label}}" />
            <div [hidden]="hideText" class="side-nav-bar__contentText">
              {{sideList.label}}
            </div>
          </div>
        </div>
      </div>
      <div>
        <button [ngClass]="{
            'side-nav-bar__button': buttonText === '<',
            'side-nav-bar__toggleButton': buttonText === '>'
          }" (click)="toggleTextVisibility()" class="side-nav-bar__button">
          <img *ngIf="!hideText" src="../../../assets/Images/side-nav-bar/closeArrow.svg" alt="Toggle Button" />
          <img *ngIf="hideText" src="../../../assets/Images/side-nav-bar/openArrow.svg" alt="open arrow" />
        </button>
      </div>
      <div [ngClass]="{
          'side-nav-bar__userWrapper': buttonText === '<',
          'side-nav-bar__userSection': buttonText === '>'
        }" class="side-nav-bar__userWrapper">
        <div [ngClass]="{
            'side-nav-bar__user': buttonText === '<',
            'side-nav-bar__admin': buttonText === '>'
          }" class="side-nav-bar__user">
          <div routerLinkActive="side-nav-bar__active" routerLink="/user-setting/user-profile"></div>
          <img *ngIf="profilePic" class="side-nav-bar__userImage cursor-pointer profimg" [src]="profilePic"
            [routerLink]="'/user-setting/user-profile'" data-bs-toggle="tooltip"
            [ngbTooltip]="(showTooltip && userDetailsData?.name)?userDetailsData?.name:''" [placement]="'right'"
            container="body" alt="" />
          <fa-icon *ngIf="!profilePic" [icon]="userCircleIcon" [routerLink]="'/user-setting/user-profile'"
            class="cursor-pointer side-nav-bar__userImage" style="color: gray; font-size: 40px" data-bs-toggle="tooltip"
            [ngbTooltip]="(showTooltip && userDetailsData?.name)?userDetailsData?.name:''" [placement]="'right'"
            container="body">
          </fa-icon>
          <div [hidden]="hideText" style="padding-left: 20px; padding-top: 30px" class="side-nav-bar__userText">
            {{ userDetailsData?.name }}
          </div>

        </div>
        <div [hidden]="hideText" class="side-nav-bar__userText pt-4">
          <app-common-button (clickEvent)="logout()" buttonClass="smallFilledButton" buttonLabel="Logout"
            [loadingIcon]="verifyLoader"></app-common-button>
        </div>
        <div [hidden]="!hideText" class="side-nav-bar__userText" (click)="logout()" (mouseenter)="showTooltip = true"
          (mouseleave)="showTooltip = false" data-bs-toggle="tooltip" [ngbTooltip]="showTooltip ? 'Log Out':''"
          [placement]="'right'" container="body" triggers="mouseenter">
          <fa-icon [icon]="faSignOut"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
