export const environment = {
  production: false,
  rolesDisabled: false,
  scanDisabled: false,
  envType: 'SDEX',//SDEX or DAMS ot CLMS
  API_URL: `https://api-gateway.dev.sdex.in/api`,
  API_ESIGN_URL: `https://api.internal.sdex.in/public-esign`,
  // API_URL: `https://api-gateway.qa.sdex.in/api`,
  // API_URL: `http://192.168.68.126:3530`,
  IMAGE_URL: `https://axivise-images.s3.ap-south-1.amazonaws.com/app/`,
  PRIVATE_KEY : 'XGgsQthLzMQvTo2hIXltdVt3O1JiPgi8',
  Dynamsoft: {
    resourcesPath: 'assets/dwt-resources',
    dwtProductKey: 't01898AUAAJf8GMRi4e5S2GDOMHDo6XrZQ0ychHLTBaEArjYpARWGo+I4kzU2GELivbqwbcvcgX/bOTu5oHlTPrIlsN0O3EDSHr+c7ODU9k5Fe6d0cPKWExjrMC27Heq8MoEz8KqAbsdhA8QCSy0H4B3X2sQD6AHqAOrVIB5wuYrzzyeOAbFf/9nQ4mQHp7Z32oC0cUoHJ285c0CGhDDl1U5LQMSenB1AD9BLQNaL7BQQHAF6gJ4A5kyPJL5K3CrM',
    uploadTargetURL: ''
  }
};

