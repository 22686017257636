import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faExpand, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPTYPES } from 'src/app/share/constants/common-constants';
import { StorageKeys } from 'src/app/share/enums/storage.enum';
import { CommonService } from 'src/app/share/services/common/common.service';
import { LookupService } from 'src/app/share/services/lookup/lookup.service';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-document-type',
  templateUrl: './add-document-type.component.html',
  styleUrl: './add-document-type.component.scss',
})
export class AddDocumentTypeComponent implements OnInit {
  documentTypeFormGroup    !: FormGroup
  @Input() tabvalue         : any;
  @Input() documentType     : any;
  @Output() submitForm :EventEmitter<any> = new EventEmitter<any>();
  @Output() closePanel      : EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() hasEditAccess : boolean;
  verifyLoader              : boolean = false;
  selfDetails               : any;
  errorMessage              : string;
  selectType                : any[] = [];
  checkedMultiList          : any = [];
  showDropDown              : boolean = true;
  listData                  : any = [];
  metaDataList              : any;
  assignedLookups           : any = [];
  envType = environment?.envType || 'SDEX';
  appType = APPTYPES;
  ocrCategoryList : any;
  selectedOcrCategory : any;
  faExpand : any = faExpand;
  faArrowRight: any = faArrowRight;

  constructor(private _fb: FormBuilder,
    private lookupService: LookupService,
    private storageService: StorageService,
    public commonService  : CommonService,
    private modal : NgbModal
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['hasEditAccess']?.currentValue){
      this.hasEditAccess = changes['hasEditAccess'].currentValue
    }
  }

  ngOnInit(): void {
    this.selfDetails = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    this.createFormGroup();
    this.validatorsAdd();
     this.getModuleReference();
    this.getMetadataList();
    this.getOcrCategoryList();
  }

  createFormGroup() {
    this.documentTypeFormGroup = this._fb.group({
      name: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]*$/)]),
      recordtype: new FormControl('', Validators.required),
      contracttype: new FormControl('', Validators.required),
      moduleRef: new FormControl(''),
      module: new FormControl('')
    })
  }

  validatorsAdd() {
    if (this.tabvalue == 'recordtype') {
      this.documentTypeFormGroup.controls["contracttype"].clearValidators();
      this.documentTypeFormGroup.controls["contracttype"].updateValueAndValidity();
    } else if (this.tabvalue == 'contracttype') {
      this.documentTypeFormGroup.controls["recordtype"].clearValidators();
      this.documentTypeFormGroup.controls["recordtype"].updateValueAndValidity();
    } else if (this.tabvalue == 'documenttype') {
      this.documentTypeFormGroup.controls["contracttype"].clearValidators();
      this.documentTypeFormGroup.controls["recordtype"].clearValidators();
      this.documentTypeFormGroup.controls["contracttype"].updateValueAndValidity();
      this.documentTypeFormGroup.controls["recordtype"].updateValueAndValidity();
    }
  }

  onCloseIconClick(value: boolean) {
    this.closePanel.emit(value);
    this.documentTypeFormGroup.reset();
    console.log(value);
  }

  editDocumentType(item: any) {
    this.documentTypeFormGroup.patchValue({
      _id: item._id,
      name: item.name,
      module: item.module
    });
  }

  getModuleReference() {
    this.lookupService.getModuleRefernce()?.subscribe({
      next: (res) => {
        if (res.success) {
          this.selectType = res.data.map((item: { _id: any; module: any; }) =>
            ({ _id: item._id, name: item.module }));
        }
      },
      error: (err) => {
        this.errorMessage = err.error?.error?.message;
      }
    });
  }

  getMetadataList() {
    this.lookupService.getMetadatalist(this.selfDetails?.rootCompany)?.subscribe({
      next: (res) => {
        if (res.success) {
          this.metaDataList = res.data;
          this.listData = this.metaDataList;
          if (this.documentType?._id) {
            this.setAssignedLookups();
          }
        }
      }, error: (err) => {
        this.errorMessage = err.error?.error?.message;
      }
    })
  }


  onSubmit() {
    this.verifyLoader = true;
    const payload = {
      name: this.documentTypeFormGroup.value.name,
      moduleRef: this.documentTypeFormGroup.value.module,
      assignedLookups: this.assignedLookups
    }
    if (this.documentType?._id) {
      this.editdocumentType(payload);
      if (this.envType === this.appType.clms) {
        this.updateContractType(payload);
      }
    } else {
      this.createDocumentType(payload);

      if (this.envType === this.appType.clms) {
        this.lookupService.addContractType(payload)?.subscribe({
          next: (res) => {
            if (res) {
              this.verifyLoader = false;
              this.onCloseIconClick(true);
            }
          }, error: (err) => {
            this.errorMessage = err.error?.error?.message;
            this.verifyLoader = false;
          }
        })
      }
    }

  }

  createDocumentType(payload: any) {
    if(this.selectedOcrCategory){
      payload.ocrCategory = this.selectedOcrCategory?._id
    }
    this.lookupService.createDocumentType(payload)?.subscribe({
      next: (res) => {
        if (res) {
          this.verifyLoader = false;
          this.onCloseIconClick(true);
          this.submitForm.emit({ success: true, message: 'Document category created successfully!' });
        }
      }, error: (err) => {
        this.errorMessage = err.error?.error?.message;
        this.verifyLoader = false;
      }
    })
  }

  editdocumentType(payload: any) {
    this.lookupService.editDocumentType(payload, this.documentType._id).subscribe({
      next: (res) => {
        if (res) {
          this.verifyLoader = false;
          this.onCloseIconClick(true);
          this.submitForm.emit({ success: true, message: 'Document category updated successfully!' });
        }
      }, error: (err) => {
        this.errorMessage = err.error?.error?.message;
        this.verifyLoader = false;
      }
    });
  }

  updateContractType(payload: any) {
    this.lookupService.updateContractType(payload, this.documentType?._id)?.subscribe({
      next: (res) => {
        if (res) {
          this.verifyLoader = false;
          this.onCloseIconClick(true);
        }
      }, error: (err) => {
        this.errorMessage = err.error?.error?.message;
        this.verifyLoader = false;
      }
    });
  }

  showDropdown() {
    this.showDropDown = !this.showDropDown;
  }

  handleFilter(event: any) {
    this.listData = this.metaDataList.filter((s: any) => s.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1);
  }

  getMultiSelectedValue(status: boolean, value: any) {
    if (status) {
      this.assignedLookups.push(value?._id);
      this.checkedMultiList.push(value.label);
      this.selectedMetaDataItem(status, value?._id);
    } else {
      let idindex = this.assignedLookups.indexOf(value._id);
      this.assignedLookups.splice(idindex, 1);
      let index = this.checkedMultiList.indexOf(value.label);
      this.checkedMultiList.splice(index, 1);
      this.selectedMetaDataItem(status, value?._id);
    }
  }

  selectedMetaDataItem(status: boolean, id: any) {
    this.listData = this.listData.map((x: any) => {
      if (status) {
        if (x._id === id) {
          return { ...x, checked: true };
        } else {
          return x;
        }
      } else if (x._id === id) {
        return { ...x, checked: false };
      } else {
        return x;
      }
    });
  }

  setAssignedLookups() {
    if (this.documentType?.assignedLookups.length > 0) {
      this.documentType?.assignedLookups.forEach((x: any) => {
        this.selectedMetaDataItem(true, x._id)
        this.getMultiSelectedValue(true, x);
      })
    }
  }

  getOcrCategoryList(){
    this.lookupService.getOcrCategoryList().subscribe({
      next :(res: any)=>{
        this.ocrCategoryList = res?.data;
      }
    })
  }

  onSelectOcrCategory(event: any){
    this.selectedOcrCategory = this.ocrCategoryList[event?.target?.selectedIndex - 1]
  }

  openMaxView(previewOcrDoc : any){
    this.modal.open(previewOcrDoc, { centered: true, fullscreen: true });
  }
}
